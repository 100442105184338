<template>
    <v-container fluid class="grey lighten-5 pa-0 ma-0">
        <q-support-submenu></q-support-submenu>
        <v-col cols="12" class="text-right mx-5" />
        <NewSupport class="pt-12" />
        <v-row justify="space-between" align="start" class="mx-7">
            <v-col cols="12">
                <page slug="service-status"></page>
            </v-col>
        </v-row>
    </v-container>
</template>
<script>
//table data + search
import QSupportSubmenu from '@/components/navigation/Submenus/QSupportSubmenu.vue';
import SupportSteps from './SupportSteps';
import Page from '@/CMS/views/index.vue'
import NewSupport from './NewSupport.vue'

export default {
    data() {
        return {
            search: '',
            sheet: false,
        }
    },
    mounted: function() {},
    computed: {

    },
    methods: {},
    components: {
        SupportSteps,
        QSupportSubmenu,
        Page,
        NewSupport
    }
}
</script>
<style>
</style>