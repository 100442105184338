<template>
    <QDynamicSubmenu
        :base-url="baseUrl"
        :default-title="title"
        :default-buttons="buttons"
        :titlelink="titlelink"
        standard-submenu
        v-on="$listeners"
    />
</template>

<script>
//table data + search
import QDynamicSubmenu from '@/components/navigation/Submenus/base/QDynamicSubmenu.vue'
import SupportTickets from '@/store/Models/SupportTicket'

export default {
    name: "QSupportSubmenu",
    props: {},
    data() {
        return {
            title: "Support",
            baseUrl: '/support',
            titlelink: [{
                label: "",
                link: "",
            }],
        }
    },
    computed: {
        loading() {
            return this.tickets.length == 0 || this.$store.state.entities.support_ticket.loading;
        },
        buttons() {
            if (this.hasTickets && !this.isDemoMode) {
                return [{
                        label: "Need Help?",
                        to: "/support",
                        icon: "fas fa-arrow-alt-circle-right",
                        id: "v-support-1",
                    },
                    {
                        label: "My Tickets",
                        to: "/support/tickets",
                        icon: "fas fa-arrow-alt-circle-right",
                        id: "v-support-5"
                    }
                ]
            }

            return [{
                label: "Need Help?",
                to: "/support",
                icon: "fas fa-arrow-alt-circle-right",
                id: "v-support-1",
            }];
        },
        tickets() {
            return SupportTickets.all();
        },
        hasTickets() {
            return this.tickets.length > 0;
        },
    },
    components: {
        QDynamicSubmenu,
    },
}
</script>
